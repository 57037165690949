<template>
  <div class="modal age-modal">
    <div class="overlay"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">
                  <b>I confirm that I am 18 years old or older</b>
                </div>
                <button class="button" @click="close">Enter</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignInModal',
  props: [],
  components: {
  },
  data: function() {
    return {
    
    }
  },
  mounted() {
    
  },
  computed: {
   
  },
  methods: {
    close() {
      localStorage.setItem('age', true)
      this.$parent.closeAgeModal();
    },
    
  }
}
</script>
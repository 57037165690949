<template>
  <main class="main page-inside products-page">
    <div class="main-wrapper">
      <div class="section shop-section">
        <img src="../images/hero-bg2.jpg" class="bg"/>
        <div class="wrapper">
          <div class="title big">SHOP OUR ITEMS</div>
          <div class="filters">
            <div class="sort">
              <div class="select-container" v-if="sortOptions.length">
                <Select2 class="select2 single" v-model="sort" 
                :options="sortOptions" 
                placeholder="SORT BY CHEAPEST"
                @select="selectSort($event)"
                />
              </div>
              <div class="select-container" v-if="categories && categories.length">
                <Select2 class="select2 single" v-model="activeCategory" 
                :options="categories" 
                placeholder="ALL CATEGORiES"
                @select="selectCategory($event)"
                />
              </div>
            </div>
            <div class="search">
              <input type="text" placeholder="Search" v-model="searchedProductText" @keyup.enter="searchSubmit"/>
              <div class="button" @click="searchSubmit">
                <img src="../images/glass.svg" class="img"/>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="product-list" v-if="productList">
              <div class="item" v-for="item in productList.data" :key="item.id" @click="openProductModal(item)">
                <img :src="imgDomain + item.img + '.png'" class="img"/>
                <div class="available desc">Available on server</div>
                <!-- <div class="available desc not">Not available on server</div> -->
                <div class="bottom">
                  <span class="name desc">{{item.name}}</span>
                  <div class="price-container">
                    <span class="price">
                      <span class="title medium currancy">{{$parent.currency}}</span>
                      <span class="title medium amount">{{item.price.toFixed(2)}}</span>
                    </span>
                    <div class="price old-price">
                      <span class="title medium"> {{$parent.currency}} {{item.old_price.toFixed(2)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="title-tiny no-products" v-if="productList.data && !productList.data.length">No products</div>
          </transition>
          <pagination v-if="totalProducts" v-model="activePage" :per-page="24" :records="totalProducts" @paginate="changePage"/>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Pagination from 'vue-pagination-2';
import Select2 from 'vue3-select2-component';

export default {
  name: 'Products',
  props: ['currency', 'currencyCode'],
  components: {
    Pagination,
    Select2
  },
  data: function() {
    return {
      imgDomain: '',
      productList: [],
      activePage: 1,
      totalProducts: null,
      searchedProductText: '',
      activeCategory: '',
      oldActiveCategory: '',
      sort: 'price',
      categories: [],
      sortOptions: [
        {
          id: 'price',
          text:'SORT BY CHEAPEST'
        }, 
        {
          id: '-price',
          text: 'SORT BY EXPENSIVE'
        }
      ],
    }
  },
  watch: {
    currencyCode: function () {
      this.filterProducts();
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getCategories();
    this.$http.get(process.env.VUE_APP_API + 'items?per_page=24')
    .then((res) => {
      this.productList = res.data;
      this.totalProducts = res.data.meta.total;
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignInModal();
      }
    })
  },
  methods: {
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categories = res.data.payload.map(({ id, name, ...rest }) => ({
          id,
          text: name,
          ...rest,
        }));
        this.categories.unshift({ id: 0, text: 'ALL CATEGORIES' });
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    selectSort(event) {
      this.sort = event.id;
      this.filterProducts();
    },
    selectCategory(event) {
      this.activeCategory = event;
      this.activePage = 1;
      this.filterProducts();
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    searchSubmit() {
      this.filterProducts();
    },
    filterProducts() {
      let addSort = 'sort=' + this.sort
      let addPage = '&page=' + this.activePage
      let addSearch = '&filter[name]=' + this.searchedProductText
      let addcCategory;
      
      if (this.activeCategory.id == 0 || this.activeCategory == '') {
       addcCategory = '';
      } else {
        addcCategory = '&filter[category]=' + this.activeCategory.id
      }
      
      this.$http.get(process.env.VUE_APP_API + 'items?' + addSort + addPage + addSearch + addcCategory + '&per_page=24')
      .then((res) => {
        this.productList = res.data
        this.totalProducts = res.data.meta.total
      })
      .catch(() => {
        
      })
    },
    openProductModal(product) {
      this.$emit('openProductModal', product)
    }
  }
}
</script>
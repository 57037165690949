<template>
  <main class="main page-inside">
    <div class="profile">
      <div class="section profile-section">
        <img src="../images/hero-bg2.jpg" class="bg"/>
        <div class="wrapper">
          <div class="title big title-main">My profile</div>
          <div class="profile-content">
            <div class="profile-info">
              <div class="avatar">
                avatar
                <img :src="$parent.profileData.avatar" class="img"/>
              </div>
              <div class="text">
                <div class="row">
                  <div class="title small">Name:</div>
                  <div class="desc">{{$parent.profileData.name}}</div>
                </div>
                <div class="row">
                  <div class="title small">Steame ID:</div>
                  <div class="desc">{{$parent.profileData.steam_id}}</div>
                </div>
              </div>
              <div class="balance-block">
                <div class="balance-block-wrapper">
                  <div class="title small">BALANCE:</div>
                  <div class="title amount">
                    {{$parent.profileData.balance}} 
                    <span class="currency">{{$parent.currency}}</span>
                  </div>
                  <div class="button" @click="$parent.openTopUpModal">
                    Top up balance
                  </div>
                </div>
              </div>
              <button class="button black" @click="$parent.logout">
                <span>LOG OUT</span>
              </button>
            </div>
            <div class="tables">
              <div class="title tables-title">History</div>
              <div class="history-info">
                <div class="title medium" v-if="$parent.orderHistory && $parent.orderHistory.length">Items</div>
                <div class="table-container" v-if="$parent.orderHistory && $parent.orderHistory.length">
                  <table>
                    <thead>
                      <tr>
                        <td>Date</td>
                        <td>Item</td>
                        <td>Count</td>
                        <td>Total price</td>
                        <td>Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in $parent.orderHistory" :key="i">
                        <td>{{$parent.formatDate(item.date)}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.count}}</td>
                        <td>
                          <b>{{item.total_price}} {{item.currency}}</b>
                        </td>
                        <td>{{item.status}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="title medium" v-if="$parent.depositHistory && $parent.depositHistory.length">Top Up</div>
                <div class="table-container" v-if="$parent.depositHistory && $parent.depositHistory.length">
                  <table>
                    <thead>
                      <tr>
                        <td>Date</td>
                        <td>Amount</td>
                        <td>Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in $parent.depositHistory" :key="i">
                        <td>{{$parent.formatDate(item.date)}}</td>
                        <td>{{item.amount}} {{item.currency}}</td>
                        <td>{{item.status}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
   
  },
  methods: {
    
  }
}
</script>
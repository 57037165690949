<template>
	<div class="modal">
      <div class="overlay" @click="$parent.closeProductModal"></div>
      <div class="wrapper">
        <img class="close" src="../images/close.svg" @click="$parent.closeProductModal" />
        <div class="container">
          <div class="preview">
            <img class="img" :src="$parent.imgDomain + $parent.chosenProoduct.img + '.png'"/>
          </div>
          <div class="text">
            <div class="title small">{{$parent.chosenProoduct.name}}</div>
            <div class="title small status">Product available on all servers</div>
            <div class="price">
              <span class="title small amount">{{$parent.chosenProoduct.price}}</span>
              <span class="title small cur">{{currency}}</span>
            </div>
            <div @click="buyProduct" class="button">GET IT</div>
            <transition name="fade">
              <div class="desc error" v-if="error">{{error}}</div>
            </transition>
            <transition name="fade">
              <div class="desc success" v-if="success">{{success}}</div>
            </transition>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
	name: 'ProductModal',
	props: ['currency'],
	data: function() {
		return {
			error: '',
      success: ''
		}
	},
	methods: {
		buyProduct() {
      this.$http.post(process.env.VUE_APP_API + 'orders?item_id=' + this.$parent.chosenProoduct.id)
      .then((res) => {
        let self = this;
        if (res.data.status === 'OK') {
          this.$parent.getProfileData();
          this.success = 'Thank you for purchase!';
          setTimeout(function() {
            this.success = '';
            self.$parent.closeProductModal();
          }, 1500)
        } 
        if (res.data.status === 'ERROR') {
          this.error = res.data.message;
          setTimeout(function() {
            self.error = '';
          }, 1500)
        } 
        
      })
      .catch((res) => {
        this.error = res;
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
      
    }
	},
}
</script>

<template>
  <main class="main page-inside payment-result-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">THANK YOU FOR YOUR PURCHASE! YOU WILL RECEIVE ALL FILES TO YOUR PROVIDED E-MAIL. IN CASE OF ANY QUESTIONS, PLEASE CONTACT US AT {{ $parent.settings ? $parent.settings.support_email.toUpperCase() : '' }}</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PaymentSuccessPage',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main"},[_c('div',{staticClass:"hero-section-container"},[_c('img',{staticClass:"img",attrs:{"src":require("../images/hero-bg.jpg")}}),_c('div',{staticClass:"section hero-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"title large"},[_vm._v(" Join the Unique Adventure ")]),_c('div',{staticClass:"title big"},[_vm._v(" on roguerust.com Server! ")]),_c('div',{staticClass:"ip-block"},[_c('div',{staticClass:"ip-block-wrapper"},[_c('div',{staticClass:"title"},[_vm._v("Start Playing Now!")]),_c('div',{staticClass:"title big"},[_vm._v(_vm._s(_vm.$parent.ip + ':' + _vm.$parent.port))])])])])])]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"section steps-section",attrs:{"id":"how"}},[_c('div',{staticClass:"wrapper"},[_c('img',{staticClass:"bg",attrs:{"src":require("../images/stepsBg.jpg")}}),_c('div',{staticClass:"steps-block"},[_c('div',{staticClass:"title title-main"},[_vm._v(" Steps on how to start playing on the server: ")]),_vm._m(2),_c('div',{staticClass:"item"},[_vm._m(3),_c('div',{staticClass:"text"},[_c('div',{staticClass:"desc"},[_vm._v(" Сopy the ip: "+_vm._s(_vm.$parent.ip + ':' + _vm.$parent.port)+" ")])])]),_vm._m(4)])])]),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section welcome-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(" At our roguerust.com ")]),_c('div',{staticClass:"desc"},[_vm._v(" you'll discover thrilling events, exclusive mods, and a friendly community. Our unique economic system and meticulously crafted gaming environment promise unforgettable adventures! ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(" In our shop ")]),_c('div',{staticClass:"desc"},[_vm._v(" you can use balance to purchase items that will help you stand out and enhance your character. All purchases are instantly delivered directly into the game under your nickname! ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section features-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"img-container"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"img",attrs:{"src":require("../images/map.svg")}})])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("Custom")]),_c('div',{staticClass:"desc"},[_vm._v(" maps and missions ")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"img-container"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"img",attrs:{"src":require("../images/prize.svg")}})])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("Special")]),_c('div',{staticClass:"desc"},[_vm._v(" events and tournaments ")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"img-container"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"img",attrs:{"src":require("../images/reward.svg")}})])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v("Unique")]),_c('div',{staticClass:"desc"},[_vm._v(" progression and reward systems ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"img-container"},[_c('div',{staticClass:"img-wrapper"},[_c('div',{staticClass:"title"},[_vm._v("1.")])])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"desc"},[_vm._v(" Installing a legal copy of Rust is a must for accessing our gaming servER ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-container"},[_c('div',{staticClass:"img-wrapper"},[_c('div',{staticClass:"title"},[_vm._v("2.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"img-container"},[_c('div',{staticClass:"img-wrapper"},[_c('div',{staticClass:"title"},[_vm._v("3.")])])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"desc"},[_vm._v(" And it's all! you can start playing, and if you want to purchase in-game items, you can visit our store ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section general-info-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"title big"},[_vm._v("General Information")]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Server Location")]),_c('div',{staticClass:"desc"},[_vm._v(" Hosted on high-performance servers to ensure minimal latency. ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Community Events")]),_c('div',{staticClass:"desc"},[_vm._v(" Weekly and monthly competitions with great prizes. ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Support")]),_c('div',{staticClass:"desc"},[_vm._v(" Dedicated support team to assist you with any issues. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section rules-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"title big"},[_vm._v("Brief Rules:")]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"img-container"},[_c('div',{staticClass:"img-wrapper"})]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"desc"},[_vm._v(" Respect all players and staff. ")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"img-container"},[_c('div',{staticClass:"img-wrapper"})]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"desc"},[_vm._v(" No cheating or exploiting game bugs. ")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"img-container"},[_c('div',{staticClass:"img-wrapper"})]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"desc"},[_vm._v(" Follow all community guidelines to ensure a fun and fair environment. ")])])])])])])
}]

export { render, staticRenderFns }